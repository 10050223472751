import React from "react"
import { graphql, Link } from 'gatsby'
import Box from '@mui/material/Box'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import '../components/layout'
import Layout from "../components/layout"
import Seo from "../components/seo"
import FrontOfficeTheme from '../style/globalTheme'

export const Head = ({ location, params, data, pageContext }) => (
    <Seo title="Mentions" />
    )
    

const CguPage = ({ data }) => {


    const Html = data.docs.edges.find(({ node }) => node.frontmatter.title === 'mentionsLegales').node.html

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FrontOfficeTheme}>

                <Layout data={data}>

                    <Box margin="15%">
                        <div dangerouslySetInnerHTML={{ __html: Html }} />
                        <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">


                            <p>
                                Conditions Générales de Vente:
                            </p>
                            <Link to="/cgu">
                                <a style={{marginLeft: '1rem'}}>
                                    CGU
                  </a>
                            </Link>
                        </Box>
                    </Box>



                </Layout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default CguPage

export const query = graphql`
 query {
  docs: allMarkdownRemark(filter: {frontmatter: {type: {eq: "legal"}}}) {
    edges {
      node {
        html
        frontmatter {
          type
          title
        }
      }
    }
  }
 }
`
